<template>
    <div class="flex-1">
        <div class="flex flex-column flex-auto">
            <div class="py-2">
                <div class="grid">
                    <h1>Konditionsblatt</h1>
                    <div class="surface-card shadow-2 border-round p-4 h-full">
                        
                        <TableKonditionsblatt />
                    </div>
                </div>
            </div>    
        </div>
    </div>

</template>
<script>
export default {
name: 'Konditionsblatt',
    computed:{
        main() {
            return this.$route.path === '/Konditionsblatt';
        }
    }
}
</script>